// debug true:测试  false:正式, "54.254.254.47";
const devUrl = ["localhost", "127.0.0.1", "192.168.2.29"];

const getEnv = () => {
    const link = window.location.href;
    let isDebug = false;
    for (let k in devUrl) {
        if (link.indexOf(devUrl[k]) !== -1) {
            isDebug = true;
        }
    }
    return isDebug;
}

// let debug = getEnv();
// let debug = true;
let debug = false;

/* 正式环境 */
// 合约地址
let blindBoxAddress = '0xbc6A97E2C937966074561901cdcBA5FD753eEa6d'; // box盲盒
let componentAddress = '0x34591C7D9c46FeBE00fce552F7fcD54eb0C27006'; // component组件
let metaIdAddress = '0x8dD02F065EE650787A414568a13948629fa21333'; // metaId
let fundAddress = '0x11d9738D1915210E3c5c0f9b4c3C31ff3bad5c79'; // fund 资产数据
let socialAddress = '0x6C84ea00de49D52e8949C9C195Cec15922FD51e8'; // social 社交
let gameAddress = '0xA088be7f48C832f84C4F41FcdE4d11E7C68b702C'; // game registry

// 前缀
let openUrlSLD = 'scan-canary.bevm.io';

/* 测试环境 */
if (debug) {
    // 合约地址
    blindBoxAddress = '0x7cA8d7D0F79A71dA7fA7E92b8c695b358A96E6F9'; // box盲盒
    componentAddress = '0xF862B0b4a43e09F562dFF84932b20f89028155f8'; // component组件
    metaIdAddress = '0x7a9bCaB1751882c4Fd704068b79B0EcF6d8577b7'; // metaId
    fundAddress = '0x9aFe02AEcFFcf5835ccd74746DcaC0E74F1bC51a'; // fund 资产数据
    socialAddress = '0x4bE5B876Ca08b36cFFFf800E92E250Af28B1dD27'; // social 社交
    gameAddress = '0x2aab9Cb32514393B45AE22224FdFA87A824157b1'; // game registry

    // 前缀
    openUrlSLD = 'scan-canary-testnet.bevm.io';
}

export {
    debug,
    openUrlSLD,
    blindBoxAddress,
    componentAddress,
    metaIdAddress,
    fundAddress,
    socialAddress,
    gameAddress
}
