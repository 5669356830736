import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/home',
        },
        {
            path: '/home',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ './views/home/Index.vue'),
            meta: {
                title: 'HOME'
            }
        },
        {
            path: '/mint',
            name: 'mint',
            component: () => import(/* webpackChunkName: "mint" */ './views/mint/Index.vue'),
            meta: {
                title: 'MINT'
            }
        },
        {
            path: '/mymetaid',
            name: 'mymetaid',
            component: () => import(/* webpackChunkName: "myMetaId" */ './views/myMetaId/Index.vue'),
            meta: {
                title: 'MYMETAID'
            }
        },
        {
            path: '/metaid/:metaId',
            name: 'metaid',
            component: () => import(/* webpackChunkName: "myMetaId" */ './views/myMetaId/Index.vue'),
            meta: {
                title: 'METAID'
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next({
            path: '/home',
        });
    } else {
        next();
    }
});

const VueRouterPush = Router.prototype.push;

Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err);
}

export default router;
