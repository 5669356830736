import axios from 'axios';

const getMetaDataInfo = function (id) {
    return new Promise((resolve, reject) => {
        axios.get(
            "https://select.ipfs.mintameta.com/ipfs/QmVo6ALAYBSoqwrjcZBzS5YYJs5mDLnn1kQyR1giC3dGg3/" + id,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getOrdinaryMetaDataInfo = function (tokenURI) {
    return new Promise((resolve, reject) => {
        axios.get(
            tokenURI,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getTokenURI = function (tokenId) {
    return new Promise((resolve, reject) => {
        axios.get(
            "https://api_kava.metaid.finance/uri?tokenId=" + tokenId,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getExternal = function (tokenId) {
    return new Promise((resolve, reject) => {
        axios.get(
            "https://api_kava.metaid.finance/external?tokenId=" + tokenId,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const uploadAssembly = function (file, name, partId) {
    return new Promise((resolve, reject) => {
        axios.post(
            "https://api_kava.metaid.finance/upload",
            {
                file: file,
                name: name,
                partId: partId
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const mergeAssembly = function (componentIds) {
    return new Promise((resolve, reject) => {
        axios.post(
            "https://api_kava.metaid.finance/merge",
            {
                componentIds
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const getGameBright = function (metaId) {
    return new Promise((resolve, reject) => {
        axios.get(
            "https://api_kava.metaid.finance/game?tokenId=" + metaId,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res;
            resolve(data);
        }).catch(err => {
            reject(err);
        })
    })
}

const http = {
    getMetaDataInfo,
    getOrdinaryMetaDataInfo,
    getTokenURI,
    getExternal,
    uploadAssembly,
    mergeAssembly,
    getGameBright
}

export default http;
